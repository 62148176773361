import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/seo";
import Text from "../components/text";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import Introduction from "../components/introduction";

const Koordinaten = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "koordinaten_01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout>
        <SEO title={intl.formatMessage({ id: "koordinaten.title" })} />
        <Title text={intl.formatMessage({ id: "koordinaten.title" })} />
        <Introduction>
          {intl.formatMessage({ id: "koordinaten.intro_1" })}
        </Introduction>
        <Introduction>
          {intl.formatMessage({ id: "koordinaten.intro_2" })}
        </Introduction>
        <Text>{intl.formatMessage({ id: "koordinaten.text_1" })}</Text>
        <Text
          css={css`
            font-weight: 700;
          `}
        >
          {intl.formatMessage({ id: "koordinaten.text_2" })}
        </Text>
        <Text>{intl.formatMessage({ id: "koordinaten.text_3" })}</Text>
        <Text>{intl.formatMessage({ id: "koordinaten.text_4" })}</Text>
        <Text>{intl.formatMessage({ id: "koordinaten.text_5" })}</Text>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, 1fr);
            grid-gap: 20px;
            margin-top: 20px;
          `}
        >
          <Img
            fluid={data.file.childImageSharp.fluid}
            key={data.file.name}
            alt={data.file.name}
          />
        </div>
        <Return />
      </Layout>
    </>
  );
};

export default Koordinaten;
